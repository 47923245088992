import { MouseEvent } from "react";
import Button from "../Button/Button";
import SparkIcon from "../svgs/SparkIcon";
import { cn } from "../../utils";

type SubmitButtonProps = {
  text: string;
  submittingText?: string;
  isSubmitting?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (event?: MouseEvent) => void;
  "data-testid"?: string;
};

const SubmitButton = ({
  text,
  submittingText,
  isSubmitting,
  disabled,
  className,
  onClick,
  "data-testid": dataTestId,
}: SubmitButtonProps) => (
  <Button
    className={cn("bg-red text-white flex items-center gap-x-2", {
      "bg-light text-grey": isSubmitting,
      [className as string]: !!className,
    })}
    type="submit"
    title={isSubmitting ? submittingText : text}
    disabled={isSubmitting || disabled}
    onClick={onClick}
    data-testid={dataTestId}
  >
    {isSubmitting ? (
      <SparkIcon className="w-5 h-5 fill-red animate-rotate" />
    ) : null}
  </Button>
);

export default SubmitButton;
