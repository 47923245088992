import Link from "next/link";
import Button from "../Button/Button";
import SubmitButton from "../SubmitButton/SubmitButton";

const TermsPrompt = ({
  closeModal,
  handleNewUser,
  modalSubmit,
  signupError,
}) => {
  return (
    <div className="flex flex-col items-center max-w-[384px]">
      <h3 className="text-2xl font-secondary font-normal mb-5">New account</h3>
      <div className="flex text-sm mb-8 text-center">
        You must accept our&nbsp;
        <Link
          className="text-blue"
          href="https://www.sparksapp.io/terms-of-use"
        >
          terms of use
        </Link>
        &nbsp;in order to use Sparks.
      </div>
      {signupError ? (
        <div className="flex text-sm mb-8 text-center">{signupError}</div>
      ) : null}
      <div className="flex flex-wrap justify-between -mt-4 -ml-6 w-full">
        <Button
          className="flex-auto mt-4 ml-6 text-black bg-cancel"
          title="No, take me back"
          onClick={closeModal}
        />
        <SubmitButton
          className="flex-auto mt-4 ml-6 bg-blue"
          text="Yes, I accept"
          submittingText="Signing up..."
          isSubmitting={modalSubmit}
          onClick={handleNewUser}
        />
      </div>
    </div>
  );
};

export default TermsPrompt;
