export type User = {
  id: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email: string;
  type: string; // TODO: make a Union type
  role: string; // TODO: make a Union type
  token?: string;
  fcmToken?: string;
};

export type Resource = {
  id: string;
  category: "BOOK" | "PODCAST_EPISODE" | "SOCIAL" | "WEBSITE" | "VIDEO";
  book?: any;
  podcast?: any;
  podcastEpisode?: any;
  social?: any;
  website?: any;
  video?: any;
  spark: Spark;
  createdAt: Date;
  updatedAt: Date;
};

export type Media = {
  type: "IMAGE" | "VIDEO";
  url: string;
  publicId: string;
  spark: Spark;
};

export type Meta = {
  id: string;
  name: string;
};

export type Spark = {
  id: string;
  notes: string;
  clusters: Cluster[];
  connections: Connection[];
  resource?: Resource;
  media?: Media[];
  meta?: Meta[];
  createdAt: Date;
  updatedAt: Date;
  user: User;
  shared: boolean;
};

export type Cluster = {
  id: string;
  name: string;
  description: string;
  sparks: Spark[];
  shared: boolean;
  createdAt: Date;
  updatedAt: Date;
  user: User;
};

export type Connection = {
  id: string;
  sparks: Spark[];
  createdAt: Date;
  updatedAt: Date;
};

export enum AnalyticsEventName {
  ADD_NEW_SPARK = "ADD_NEW_SPARK",
  VIEW_SPARK_CLUSTER = "VIEW_SPARK_CLUSTER",
  VIEW_CONNECTED_SPARK = "VIEW_CONNECTED_SPARK",
  VIEW_SPARK_FROM_UPDATE = "VIEW_SPARK_FROM_UPDATE",
  VIEW_CLUSTER_FROM_UPDATE = "VIEW_CLUSTER_FROM_UPDATE",
  SWITCH_TO_USER_FOR_PROCESSING = "SWITCH_TO_USER_FOR_PROCESSING",
  REMOVE_SPARK_FROM_CLUSTER = "REMOVE_SPARK_FROM_CLUSTER",
  CREATE_CLUSTER = "CREATE_CLUSTER",
  DELETE_CLUSTER = "DELETE_CLUSTER",
  DELETE_SPARK = "DELETE_SPARK",
  EDIT_SPARK = "EDIT_SPARK",
  CLICK_LINK_TO_ORIGINAL_RESOURCE = "CLICK_LINK_TO_ORIGINAL_RESOURCE",
  EDIT_CLUSTER = "EDIT_CLUSTER",
  FOLLOW_CLUSTER = "FOLLOW_CLUSTER",
  UNFOLLOW_CLUSTER = "UNFOLLOW_CLUSTER",
  LOGOUT = "LOGOUT",
  CLICK_HELP = "CLICK_HELP",
  CLICK_YOUR_SUBSCRIPTION = "CLICK_YOUR_SUBSCRIPTION",
  CLICK_IOS_APP = "CLICK_IOS_APP",
  CLICK_BROWSER_EXTENSION = "CLICK_BROWSER_EXTENSION",
  CLICK_COMMUNITY = "CLICK_COMMUNITY",
  ADD_SPARK_TO_CLUSTER = "ADD_SPARK_TO_CLUSTER",
  ADD_CONNECTION = "ADD_CONNECTION",
  CHANGE_SPARK_SHARE_SETTING = "CHANGE_SPARK_SHARE_SETTING",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  REMOVE_CONNECTION = "REMOVE_CONNECTION",
  VIEW_CLUSTERS = "VIEW_CLUSTERS",
  VIEW_SPARKS = "VIEW_SPARKS",
  VIEW_UPDATES = "VIEW_UPDATES",
  VIEW_CLUSTER = "VIEW_CLUSTER",
  VIEW_SPARK = "VIEW_SPARK",
  GOOGLE_LOGIN = "GOOGLE_LOGIN",
  GOOGLE_SIGNUP = "GOOGLE_SIGNUP",
  MOBILE_LOGIN = "MOBILE_LOGIN",
  MOBILE_SIGNUP = "MOBILE_SIGNUP",
}

export type AnalyticsEventData = {};

// export type Spark = {
//   notes: string;
//   spark_id: string;
//   userId: string;
//   user: string;
//   connections: string[];
// };
